/* eslint-disable consistent-return */
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { uuid } from 'vue-uuid';
import router from '../router';
import { sendOtherTabUserSettingsChange } from '../realTimeConnection/broadcastChannel';

Vue.use(require('vue-moment'), {
    moment,
});

Vue.moment.tz.setDefault('Europe/London');
const merge = require('deepmerge');

export default {
    namespaced: true,
    state: {
        pages: {
            userDashboard: {
                user: {
                    activeTab: '',
                    views: [],
                    dashboardIdsInOrder: [],
                },
            },
            boa: {
                currentView: 'tableMap',
                showNivBreakdown: false,
                chartBidOfferToShow: 'offers',
                chartBidOnOffUpDown: ['onOff', 'upDown'],
                chartNoticeToDeviateFilter: '',
                chartMaxPrice: 8000,
                chartHideUnwind: false,
                chartHideWind: false,
            },
            boaComparer: {
                linkBoaComparerFilters: true,
            },
            bingo: {
                gridLayout: [],
                config: {},
            },
            bingoNew: {
                activeBoard: '',
                boards: {},
                boardIdsInOrder: [],
            },
            plantActivity: {
                firstColumnOption: 'siteWide',
            },
            subscriptions: {
                allSubscriptions: [],
                subscriptionsInOrder: [],
            },
            map: {},
            boaDashboardMap: {},
            historicQueries: [],
            bmDates: {
                from: Vue.moment().toDate(), // OLD
                to: Vue.moment().toDate(), // OLD
                dateObject: null,
            },
            remit: {

            },
            pageDates: {},
        },
        boaAcceptedTableSortOption: {},
        colour: 'Dark',
        country: 'Gb',
        darkMode: true,
        bidColour: '',
        offerColour: '',
        bingoColourOverrides: {},
        bingoStringOverrides: {},
        useTradingPeriod: false, // LEGACY ITEM, DO NOT DIRECTLY REFERENCE, USE GETTER 'boaWindowType' INSTEAD
        boaWindowType: null, // DO NOT DIRECTLY REFERENCE, USE GETTER 'boaWindowType' INSTEAD
        autoRollover: true,
        showTimer: false,
        filterAcceptedUnwinds: false,
        filterUnacceptedUnwinds: false,
        filterTrackedPlants: false,
        orderAcceptedFirst: false,
        userId: null,
        userEmail: null,
        sideNavVisible: true,
        chartHoverChoice: true,
        stepChartOption: false,
        FullChartFunctionality: false,
        fullDAChartAxes: false,
        forecastHistorySeriesToShow: null,
        melBelowPnToleranceToUse: null,
        excelAsCsv: false,
        suppressBSADWarnings: false,
        lockedAccount: 'unlocked',
        tabUpdateValue: 'none',
        timeFormatValue: '24h',
        tableUnitValue: 'header',
        terms: {
            agreed: false,
            date: null,
        },
        chosenPlants: [],
        chosenPlantGroupName: null,
        chosenPlantsByGroup: { },
        globalBingoChoices: {},
        globalBingoSoundChoices: {},
        plantSelection: 'default',
        plantActivityDisplayOption: 'Default',
        showGeneration: false,
        reversePlantActivityOrder: false,
        genericTableFilters: {},
        reverseNivSign: false,
        reverseNivForecastSign: false,
        nivInMwh: false,
        versionNumber: 0.3,
        minutesDelayToReport: 4,
        showZoneInPlantNameBingo: true,
        defaultPlantGroupColour: 'rgb(0, 163, 199)',
        chosenPlantGroupColour: 'rgb(0, 163, 199)',
        plantGroupColourOverride: false,
        plantGroupColourOverrideByGroup: {},
        leaderboardCapacityInMwh: false,
        showNearestPointOnTooltip: false,
        longTermSettings: {
            granularity: 'yearly',
            runSelected: '01/01/2021',
        },
        useColouredFlagsForFlaggedBoaActions: true,
    },
    getters: {
        isDarkMode (state) {
            return state.colour === 'Dark';
        },
        boaAcceptedTableSortOption (state) {
            return state.boaAcceptedTableSortOption;
        }
    },
    actions: {
        async getUserSettings ({
            state,
            commit,
            dispatch,
        }, id) {
            return axios.get(`${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/getUserSettings/`).then(async (response) => {
                if (response.status === 204) {
                    dispatch('createNewUser', id);
                } else {
                    try {
                        const settingsFromBackend = response.data.otherSettings.data;
                        settingsFromBackend.pages = {};
                        settingsFromBackend.pages.userDashboard = {};
                        settingsFromBackend.pages.userDashboard.user = {};
                        settingsFromBackend.pages.userDashboard.user.views = [];
                        settingsFromBackend.pages.userDashboard.user.dashboardIdsInOrder = [];
                        settingsFromBackend.pages.bingoNew = {};
                        settingsFromBackend.pages.bingoNew.boards = {};
                        settingsFromBackend.pages.bingoNew.boardIdsInOrder = [];
                        if (response.data.activeTab) {
                            settingsFromBackend.pages.userDashboard.user.activeTab = response.data.activeTab.data;
                        }
                        if (response.data.bingoActiveTab) {
                            settingsFromBackend.pages.bingoNew.activeBoard = response.data.bingoActiveTab.data;
                        }

                        function addDataWithOrderingAndReturnIfNeedToDispatchOrder (responseObject, inMemoryOrder, inMemoryObjects, storeInMemAsDictionary) {
                            let missingData = false;
                            if (responseObject) {
                                if (responseObject.order) {
                                    responseObject.order.data.forEach((orderingData) => {
                                        if (responseObject.objectsToStore != null && responseObject.objectsToStore.hasOwnProperty(orderingData)) inMemoryOrder.push(orderingData);
                                    });
                                }

                                if (responseObject.objectsToStore) {
                                    inMemoryOrder.forEach((dashboardId) => {
                                        if (responseObject.objectsToStore[dashboardId]) {
                                            if (storeInMemAsDictionary) inMemoryObjects[dashboardId] = responseObject.objectsToStore[dashboardId].data;
                                            else inMemoryObjects.push(responseObject.objectsToStore[dashboardId].data);
                                        }
                                    });
                                    Object.keys(responseObject.objectsToStore).forEach((dashboardKey) => {
                                        const indexFound = inMemoryOrder.findIndex((x) => x === dashboardKey);
                                        if (indexFound < 0) {
                                            missingData = true;
                                            if (storeInMemAsDictionary) inMemoryObjects[dashboardKey] = responseObject.objectsToStore[dashboardKey].data;
                                            else inMemoryObjects.push(responseObject.objectsToStore[dashboardKey].data);
                                        }
                                    });
                                }
                            }
                            return missingData;
                        }
                        const missingData = addDataWithOrderingAndReturnIfNeedToDispatchOrder(response.data.dashboardsWithOrder, settingsFromBackend.pages.userDashboard.user.dashboardIdsInOrder, settingsFromBackend.pages.userDashboard.user.views, false);

                        const missingDataBingo = addDataWithOrderingAndReturnIfNeedToDispatchOrder(response.data.bingoWithOrder, settingsFromBackend.pages.bingoNew.boardIdsInOrder, settingsFromBackend.pages.bingoNew.boards, true);
                        let boardCount = 1;
                        const bingoDashboardsNeedUpdating = [];
                        Object.keys(settingsFromBackend.pages.bingoNew.boards).forEach((board) => {
                            if (settingsFromBackend.pages.bingoNew.boards[board].name == null) {
                                settingsFromBackend.pages.bingoNew.boards[board].name = `Board ${boardCount}`;
                                boardCount += 1;
                            }
                            if (settingsFromBackend.pages.bingoNew.boards[board].id !== board) {
                                settingsFromBackend.pages.bingoNew.boards[board].id = board;
                                bingoDashboardsNeedUpdating.push(board);
                            }
                        });
                        const missingBingoActiveTab = settingsFromBackend.pages.bingoNew.activeBoard == null || settingsFromBackend.pages.bingoNew.activeBoard == '';
                        let unknownBingoActiveTab = false;
                        if (!missingBingoActiveTab) {
                            const indexOfBingoActiveTab = Object.keys(settingsFromBackend.pages.bingoNew.boards).findIndex((i) => i === settingsFromBackend.pages.bingoNew.activeBoard);
                            unknownBingoActiveTab = indexOfBingoActiveTab < 0;
                        }
                        if (unknownBingoActiveTab || missingBingoActiveTab) {
                            const boardKeys = Object.keys(settingsFromBackend.pages.bingoNew.boards);
                            if (boardKeys.length > 0) settingsFromBackend.pages.bingoNew.activeBoard = boardKeys[0];
                            else settingsFromBackend.pages.bingoNew.activeBoard = '';
                        }
                        settingsFromBackend.pages.map = {};
                        settingsFromBackend.pages.historicQueries = [];

                        settingsFromBackend.pages.bmDates = {
                            from: Vue.moment().toDate(),
                            to: Vue.moment().toDate(),
                        };
                        if (response.data.pageData) {
                            Object.keys(response.data.pageData).forEach((pageName) => {
                                if (pageName !== 'bingoNew') {
                                    settingsFromBackend.pages[pageName] = response.data.pageData[pageName].data;
                                }
                            });
                        }
                        if (settingsFromBackend.pages.historicQueries == null)settingsFromBackend.pages.historicQueries = [];
                        settingsFromBackend.pages.subscriptions = {};
                        settingsFromBackend.pages.subscriptions.allSubscriptions = [];
                        settingsFromBackend.pages.subscriptions.subscriptionsInOrder = [];
                        const missingNotifications = addDataWithOrderingAndReturnIfNeedToDispatchOrder(response.data.subscriptionsWithOrder, settingsFromBackend.pages.subscriptions.subscriptionsInOrder, settingsFromBackend.pages.subscriptions.allSubscriptions, false);

                        const toPost = {
                            added: true,
                        };

                        settingsFromBackend.genericTableFilters = {};
                        if (response.data.tableFilters) {
                            Object.keys(response.data.tableFilters).forEach((filterKey) => {
                                settingsFromBackend.genericTableFilters[filterKey] = response.data.tableFilters[filterKey].data;
                            });
                        }

                        commit('mergeSettings', settingsFromBackend);
                        if (missingDataBingo) dispatch('moveBingoOrder');
                        if (bingoDashboardsNeedUpdating.length > 0) {
                            dispatch('batchUpdateBingo', bingoDashboardsNeedUpdating);
                        }
                        const currentBoards = Object.keys(settingsFromBackend.pages.bingoNew.boards);
                        if (currentBoards.length === 0) {
                            dispatch('addBingoBoard', {
                                board: {
                                    rowsArray: [1, 2, 3, 4, 5, 6, 7],
                                    columnsArray: [1, 2, 3, 4, 5, 6, 7, 8],
                                    cards: [],
                                    separatorRows: [],
                                    separatorColumns: [],
                                    chosenSize: 'Medium',
                                    name: 'Board 1',
                                    id: `${id}bingoMultiple`,
                                },
                            });
                        }
                        if (missingNotifications) dispatch('updateNotificationSettings', toPost);
                        if (missingData) dispatch('moveDashboardOrder');
                    } catch (error) {
                        const sendDetails = {
                            notes: error.toString(),
                            type: 'BROKE',
                            email: 'UNKNOWN',
                            userId: id,
                            page: '[INTERNAL] USER SETTINGS URGENT',
                        };

                        axios.post(`${process.env.VUE_APP_STATIC_API}/staticApi/feedback/`, sendDetails).then(() => {
                            this.selectType = '';
                            this.notes = '';
                        });

                        router.push('/logout');
                        return;
                    }

                    if (state.versionNumber == null || state.versionNumber < 0.2) {
                        commit('mergeOldAndNewSettings');
                        commit('updateDashboardOrder');
                        dispatch('replaceAllDashboardSettings');
                        dispatch('updateBasicUserSettings');
                    }
                    if (state.versionNumber == null || state.versionNumber < 0.3) {
                        commit('convertOldBingoToNew');
                        dispatch('updateCurrentBingoBoardInDatabase');
                        dispatch('updateBasicUserSettings');
                    }
                }
            });
        },
        updateSubscriptionSettings ({
            state,
        }) {
            if (state.lockedAccount !== 'uploadLocked') {
                return new Promise((resolve) => {
                    const toPost = {
                        data: state.pages.subscriptions,
                        pageName: 'subscriptions',
                    };
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postpagesettings`,
                            toPost,
                        ),
                    );
                });
            }
        },
        updateBingoOldSettings ({
            state,
        }) {
            if (state.lockedAccount !== 'uploadLocked') {
                return new Promise((resolve) => {
                    const toPost = {
                        data: state.pages.bingo,
                        pageName: 'bingo',
                    };
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postpagesettings`,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updatePageDataGeneric ({
            state,
        }, pageName) {
            if (state.lockedAccount !== 'uploadLocked') {
                return new Promise((resolve) => {
                    const toPost = {
                        data: state.pages[pageName],
                        pageName,
                    };
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postpagesettings`,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateGenericTableFilterUserSettings ({
            state,
        }, value) {
            if (state.lockedAccount !== 'uploadLocked') {
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postgenerictablefilters`,
                            value,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateActiveTabSettings ({ state }, type) {
            if (state.lockedAccount !== 'uploadLocked') {
                const toPost = {
                    type,
                };
                if (type === 'dashboard') {
                    toPost.data = state.pages.userDashboard.user.activeTab;
                }
                if (type === 'bingo') {
                    toPost.data = state.pages.bingoNew.activeBoard;
                }
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/activeTab`,
                            toPost,
                        ).then(() => {
                            // sendOtherTabUserSettingsChange();
                            // Tab change shouldn't alert other dashboard
                        }),
                    );
                });
            }
        },
        replaceAllDashboardSettings (
            {
                state,
            },
        ) {
            if (state.lockedAccount !== 'uploadLocked') {
                const idsToPost = state.pages.userDashboard.user.dashboardIdsInOrder;
                const dashboardsWithIdsToPost = [];
                state.pages.userDashboard.user.views.forEach((dashboard) => {
                    dashboardsWithIdsToPost.push({
                        id: dashboard.id,
                        data: dashboard,
                    });
                });
                const toPost = {
                    dashboards: dashboardsWithIdsToPost,
                    dashboardIds: idsToPost,
                    activeTab: state.pages.userDashboard.user.activeTab,
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/replaceAll`,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        deleteOrderedItemFromDb ({
            state,
        }, data) {
            if (state.lockedAccount !== 'uploadLocked') {
                let idsToPost = null;
                if (data.type === 'notification') idsToPost = state.pages.subscriptions.subscriptionsInOrder;
                if (data.type === 'dashboard') idsToPost = state.pages.userDashboard.user.dashboardIdsInOrder;
                if (data.type === 'bingo') idsToPost = state.pages.bingoNew.boardIdsInOrder;
                const toPost = {
                    id: data.idDeleted,
                    ordering: idsToPost,
                    type: data.type,
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/delete`,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateCurrentBingoBoardInDatabase ({
            state,
        }, options) {
            if (state.lockedAccount !== 'uploadLocked') {
                const endpoint = `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/update`;
                const boardId = state.pages.bingoNew.activeBoard;
                const toPost = {
                    data: state.pages.bingoNew.boards[boardId],
                    id: boardId,
                    ordering: null,
                    type: 'bingo',
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            endpoint,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateBingoDataInDatabase ({
            state,
        }, options) {
            if (state.lockedAccount !== 'uploadLocked') {
                let viewToTarget = null;
                let idsToPost = null;
                let boardId = '';
                const endpoint = `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/update`;
                if (options.boardId) {
                    boardId = options.boardId;
                    viewToTarget = state.pages.bingoNew.boards[boardId];
                }
                if (options.idsChanged) {
                    idsToPost = state.pages.bingoNew.boardIdsInOrder;
                }

                const toPost = {
                    data: viewToTarget,
                    id: boardId,
                    ordering: idsToPost,
                    type: 'bingo',
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            endpoint,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateDashboardDataInDatabase ({ state }, options) {
            if (state.lockedAccount !== 'uploadLocked') {
                let viewToTarget = null;
                let dashboardId = '';
                const endpoint = `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/update`;
                if (options.dashboardViewKey != null) {
                    viewToTarget = state.pages.userDashboard.user.views[options.dashboardViewKey];
                    dashboardId = viewToTarget.id;
                } else if (options.dashboardId != null) {
                    dashboardId = options.dashboardId;
                    viewToTarget = state.pages.userDashboard.user.views.find(o => o.id === dashboardId);
                }

                let idsToPost = null;
                if (options.idsChanged) {
                    idsToPost = state.pages.userDashboard.user.dashboardIdsInOrder;
                }

                const toPost = {
                    data: viewToTarget,
                    id: dashboardId,
                    ordering: idsToPost,
                    type: 'dashboard',
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            endpoint,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateNotificationSettings ({
            state,
        }, options) {
            if (state.lockedAccount !== 'uploadLocked') {
                let idsToPost = null;
                const endpoint = `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/itemsWithOrdering/updateNotification`;

                const viewToTarget = options.subscription;
                const dashboardId = options.subscriptionId;
                if (options.added) {
                    idsToPost = state.pages.subscriptions.subscriptionsInOrder;
                }

                const toPost = {
                    data: viewToTarget,
                    id: dashboardId,
                    ordering: idsToPost,
                    type: 'notification',
                };
                return new Promise((resolve) => {
                    resolve(
                        axios.post(
                            endpoint,
                            toPost,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        updateBasicUserSettings ({
            state,
        }, changingLockedSetting = false) {
            if (state.lockedAccount !== 'uploadLocked' || changingLockedSetting) {
                return new Promise((resolve) => {
                    const shallowClone = { ...state };
                    delete shallowClone.pages;
                    delete shallowClone.genericTableFilters;
                    // delete shallowClone.mapPagePreferences;
                    // delete shallowClone.bmLeaderboardDates;
                    // delete shallowClone.historicAnalysisQueries;
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postusersettings`,
                            shallowClone,
                        ).then(() => {
                            sendOtherTabUserSettingsChange();
                        }),
                    );
                });
            }
        },
        createNewUser ({
            state,
        }, id) {
            if (state.lockedAccount !== 'uploadLocked') {
                return new Promise((resolve) => {
                    // const newUserTemp = {
                    //     settings: state,
                    //     id: state.userId,
                    // };
                    const toPost = {
                        id,
                        settings: state,
                    };
                    resolve(
                        axios.post(
                            `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/postusersettings/newUser`,
                            // 'https://enact.azurewebsites.net/api/CreateNewUser',
                            toPost,
                        ),
                    );
                });
            }
        },
        toggleColourMode ({
            commit,
            dispatch,
        }, colour) {
            commit('toggleColourMode', colour);
            dispatch('updateBasicUserSettings');
        },
        sortBoaAcceptedTable ({
            commit,
            dispatch,
        }, sort) {
            commit('sortBoaAcceptedTable', sort);
            dispatch('updateBasicUserSettings');
        },
        setDefaultCountry ({
            commit,
            dispatch,
        }, country) {
            commit('setDefaultCountry', country);
            dispatch('updateBasicUserSettings');
        },
        updateBodColour ({
            commit,
            dispatch,
        }, data) {
            commit('updateBodColour', data);
            dispatch('updateBasicUserSettings');
        },
        updateBingoColour ({
            commit,
            dispatch,
        }, data) {
            commit('updateBingoColour', data);
            dispatch('updateBasicUserSettings');
        },
        insertBingoRow ({
            commit,
            dispatch,
        }, data) {
            commit('insertBingoRow', data);
            if (data.isSeparatorRow) {
                commit('insertBingoSepRow', data);
            }
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        addTemporaryAddBingoCard ({
            commit,
            dispatch,
        }, data) {
            commit('addBingoCardData', data);
        },
        setBingoChosenSize ({
            commit,
            dispatch,
        }, data) {
            commit('setBingoChosenSize', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        replaceBingoCard ({
            commit,
            dispatch,
        }, data) {
            commit('replaceBingoCard', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        insertBingoColumn ({
            commit,
            dispatch,
        }, data) {
            commit('insertBingoColumn', data);
            if (data.isSeparatorColumn) {
                commit('insertBingoSepColumn', data);
            }
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        deleteBingoColumn ({
            commit,
            dispatch,
        }, data) {
            commit('deleteBingoColumn', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        addBingoCardData ({
            commit,
            dispatch,
        }, data) {
            commit('addBingoCardData', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        deleteBingoRow ({
            commit,
            dispatch,
        }, data) {
            commit('deleteBingoRow', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        setBingoCardData ({
            commit,
            dispatch,
        }, data) {
            commit('setBingoCardData', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        deleteBingoCardDataNoUpdate ({
            commit,
            dispatch,
        }, data) {
            commit('deleteBingoCardData', data);
        },
        deleteBingoCardData ({
            commit,
            dispatch,
        }, data) {
            commit('deleteBingoCardData', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        addBulkCards ({
            commit,
            dispatch,
        }, data) {
            commit('addBulkCards', data);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        setNewBingoAsActive ({ commit, dispatch }, boardId) {
            commit('updateBingoBoard', boardId);
            dispatch('updateActiveTabSettings', 'bingo');
        },
        addBingoBoard ({ commit, dispatch }, data) {
            // data: { name: '', board: {}}
            commit('addBingoBoard', data);
            commit('updateBingoBoard', data.board.id);
            commit('updateBingoOrder');
            const options = {
                boardId: data.board.id,
                idsChanged: true,
            };
            dispatch('updateBingoDataInDatabase', options);
            dispatch('updateActiveTabSettings', 'bingo');
        },
        renameBingoBoard ({ commit, dispatch }, data) {
            // data: { oldName: '', newName: '' }
            commit('renameBingoBoard', data);
            const options = {
                boardId: data.id,
                idsChanged: false,
            };
            dispatch('updateBingoDataInDatabase', options);
        },
        removeBingoBoard ({ commit, dispatch }, boardId) {
            commit('removeBingoBoard', boardId);
            const toPost = {
                idDeleted: boardId,
                type: 'bingo',
            };
            dispatch('deleteOrderedItemFromDb', toPost);
        },
        addUserSubscription ({
            state,
            commit,
            dispatch,
        }, subscriptionItem) {
            commit('addSubscription', subscriptionItem);
            commit('updateNotificationOrder');
            const toPost = {
                subscriptionId: subscriptionItem.uiId,
                subscription: subscriptionItem,
                added: true,
            };
            dispatch('updateNotificationSettings', toPost);
            dispatch('subscriptions/postToBackendAboutSubscription', {
                subscriptions: state.pages.subscriptions.allSubscriptions,
            }, { root: true });
        },
        updateUserSubscription ({
            state,
            commit,
            dispatch,
        }, subAndKey) {
            commit('updateSubscription', subAndKey);
            commit('updateNotificationOrder');
            const toPost = {
                subscriptionId: subAndKey.sub.uiId,
                subscription: subAndKey.sub,
            };
            dispatch('updateNotificationSettings', toPost);
            dispatch(
                'subscriptions/postToBackendAboutSubscription',
                {
                    subscriptions: state.pages.subscriptions.allSubscriptions,
                },
                { root: true },
            );
        },
        deleteUserSubscription ({
            state,
            commit,
            dispatch,
        }, subAndKey) {
            commit('deleteSubscription', subAndKey);
            commit('updateNotificationOrder');
            const toPost = {
                idDeleted: subAndKey.sub.uiId,
                type: 'notification',
            };
            dispatch('deleteOrderedItemFromDb', toPost);
            dispatch(
                'subscriptions/postToBackendAboutSubscription',
                {
                    subscriptions: state.pages.subscriptions.allSubscriptions,
                },
                { root: true },
            );
        },
        setBoaWindowOption ({ commit, dispatch }, value) {
            commit('setBoaWindowOption', value);
            dispatch('getAndUpdateCurrentPeriod');
            dispatch('updateBasicUserSettings');
        },
        async getAndUpdateCurrentPeriod ({ commit, dispatch, rootState }, getPageData = true) {
            await dispatch('getCurrentPeriod', null, {
                root: true,
            }).then(
                () => {
                    commit('jumpToCurrentPeriod', null, {
                        root: true,
                    });
                    if (getPageData && rootState.ui.pageName === 'boa') {
                        dispatch('getBoaData', null, {
                            root: true,
                        });
                    }
                    if (getPageData && rootState.ui.pageName === 'boaComparer') {
                        dispatch('getBoaComparerData', null, {
                            root: true,
                        });
                    }
                },
            );
        },
        setAutorollOption ({
            commit,
            dispatch,
        }, value) {
            commit('setAutorollOption', value);
            dispatch('updateBasicUserSettings');
        },
        setMapPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setMapPagePreferences', value);
            dispatch('updatePageDataGeneric', 'map');
        },
        setPlantIndexPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantIndexPagePreferences', value);
            dispatch('updatePageDataGeneric', 'plantIndex');
        },
        setBoaDashboardMapPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setBoaDashboardMapPagePreferences', value);
            dispatch('updatePageDataGeneric', 'boaDashboardMap');
        },
        setMapLivePagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setMapLivePagePreferences', value);
            dispatch('updatePageDataGeneric', 'mapLive');
        },
        setRemitPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setRemitPagePreferences', value);
            dispatch('updatePageDataGeneric', 'remit');
        },
        setBmLeaderboardPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setBmLeaderboardPagePreferences', value);
            dispatch('updatePageDataGeneric', 'bmLeaderboard');
        },
        setPlantIndexPagePreferences ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantIndexPagePreferences', value);
            dispatch('updatePageDataGeneric', 'plantIndex');
        },
        setBoaPreference ({
            commit,
            dispatch,
        }, value) {
            commit('setBoaPreferences', value);
            dispatch('updatePageDataGeneric', 'boa');
        },
        setReverseNivSign ({
            commit,
            dispatch,
        }, value) {
            commit('setReverseNivSign', value);
            dispatch('updateBasicUserSettings');
        },
        setReverseNivForecastSign ({
            commit,
            dispatch,
        }, value) {
            commit('setReverseNivForecastSign', value);
            dispatch('updateBasicUserSettings');
        },
        setnivInMwh ({
            commit,
            dispatch,
        }, value) {
            commit('setnivInMwh', value);
            dispatch('updateBasicUserSettings');
        },
        setleaderboardCapacityInMwh ({
            commit,
            dispatch,
        }, value) {
            commit('setleaderboardCapacityInMwh', value);
            dispatch('updateBasicUserSettings');
        },
        setShowTimer ({
            commit,
            dispatch,
        }, value) {
            commit('setShowTimer', value);
            dispatch('updateBasicUserSettings');
        },
        setfilterUnacceptedUnwinds ({
            commit,
            dispatch,
        }, value) {
            commit('setfilterUnacceptedUnwinds', value);
            dispatch('updateBasicUserSettings');
        },
        setfilterTrackedPlants ({
            commit,
            dispatch,
        }, value) {
            commit('setfilterTrackedPlants', value);
            dispatch('updateBasicUserSettings');
        },
        setLinkBoaComparerFilters ({
            commit,
            dispatch,
        }, value) {
            commit('setLinkBoaComparerFilters', value);
            dispatch('updateBasicUserSettings');
        },
        setfilterAcceptedUnwinds ({
            commit,
            dispatch,
        }, value) {
            commit('setfilterAcceptedUnwinds', value);
            dispatch('updateBasicUserSettings');
        },
        setOrderAcceptedFirst ({
            commit,
            dispatch,
        }, value) {
            commit('setOrderAcceptedFirst', value);
            dispatch('updateBasicUserSettings');
        },
        setGlobalBingoChoices ({
            commit,
            dispatch,
        }, value) {
            commit('setGlobalBingoChoices', value);
            dispatch('updateBasicUserSettings');
        },
        elexonMinuteDelay ({
            commit,
            dispatch,
        }, value) {
            commit('elexonMinuteDelay', value);
            dispatch('updateBasicUserSettings');
        },
        setChosenPlants ({
            commit,
            dispatch,
        }, value) {
            commit('setChosenPlants', value);
            dispatch('updateBasicUserSettings');
        },
        setChosenPlantColour ({
            commit,
            dispatch,
        }, value) {
            commit('setChosenPlantColour', value);
            dispatch('updateBasicUserSettings');
        },
        setPlantColourOverride ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantColourOverride', value);
            dispatch('updateBasicUserSettings');
        },
        removeChosenPlants ({
            commit,
            dispatch,
        }, value) {
            commit('removeChosenPlants', value);
            dispatch('updateBasicUserSettings');
        },
        renameChosenPlants ({
            commit,
            dispatch,
        }, value) {
            commit('renameChosenPlants', value);
            dispatch('updateBasicUserSettings');
        },
        uploadNewHistoricAnalysisQuery ({
            commit,
            dispatch,
        }, value) {
            commit('setNewHistoricAnalysisQuery', value);
            dispatch('updatePageDataGeneric', 'historicQueries');
        },
        deleteHistoricAnalysisQuery ({
            commit,
            dispatch,
        }, value) {
            commit('deleteHistoricAnalysisQuery', value);
            dispatch('updatePageDataGeneric', 'historicQueries');
        },
        setGenericTableFilters ({
            commit,
            dispatch,
        }, value) {
            commit('setGenericTableFilters', value);
            dispatch('updateGenericTableFilterUserSettings', value);
        },
        deleteGenericTableFilters ({
            commit,
            dispatch,
        }, value) {
            commit('deleteGenericTableFilters', value);
            dispatch('updateGenericTableFilterUserSettings', value);
        },
        updateBoaStackSort ({
            commit, dispatch,
        }, data) {
            commit('updateBoaStackSort', data);
            const options = {
                dashboardViewKey: data.activeTabIndex,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        updateModuleSizeAndPosition ({
            commit, dispatch,
        }, data) {
            data.modules.forEach(module => {
                commit('updateModuleSizeAndPosition', { dashboardIndex: data.activeTabIndex, module });
            });
            const options = {
                dashboardViewKey: data.activeTabIndex,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        updateForecastHistory ({
            commit, dispatch,
        }, data) {
            commit('updateForecastHistory', data);
            const options = {
                dashboardViewKey: data.activeTabIndex,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        updateUserModuleDate ({
            commit, dispatch,
        }, data) {
            commit('updateModuleDate', data);
            const options = {
                dashboardViewKey: data.viewKey,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        updateExtraChartOption ({
            commit, dispatch,
        }, data) {
            if (data.viewKey !== -1 && data.moduleKey !== -1) {
                commit('updateExtraChartOption', data);
                const options = {
                    dashboardViewKey: data.viewKey,
                    idsChanged: false,
                };
                dispatch('updateDashboardDataInDatabase', options);
            }
        },
        updateUserModuleNote ({
            commit, dispatch,
        }, data) {
            commit('updateModuleNote', data);
            const options = {
                dashboardViewKey: data.viewKey,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        removeUserModule ({
            commit,
            dispatch,
        }, data) {
            commit('removeUserModule', data);
            const options = {
                dashboardId: data.viewId,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
            if (data.isLiveTableBeingDeleted) {
                const deleteLiveTableOptions = {
                    name: `liveTable_${data.moduleId}`,
                    filters: null,
                };
                dispatch('deleteGenericTableFilters', deleteLiveTableOptions);
            }
        },
        setShowGeneration ({
            commit,
            dispatch,
        }, value) {
            commit('setShowGeneration', value);
            dispatch('updateBasicUserSettings');
        },
        setReversePlantActivityOrder ({
            commit,
            dispatch,
        }, value) {
            commit('setReversePlantActivityOrder', value);
            dispatch('updateBasicUserSettings');
        },
        setPlantActivityFirstColumnOption ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantActivityFirstColumnOption', value);
            dispatch('updateBasicUserSettings');
        },
        setShowZoneInPlantNameBingo ({
            commit,
            dispatch,
        }, value) {
            commit('setShowZoneInPlantNameBingo', value);
            dispatch('updateBasicUserSettings');
        },
        setPlantActivityDisplayOption ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantActivityDisplayOption', value);
            dispatch('updateBasicUserSettings');
        },
        setPlantSelections ({
            commit,
            dispatch,
        }, value) {
            commit('setPlantSelections', value);
            dispatch('updateBasicUserSettings');
        },
        updateBmLeaderboardDate ({
            commit,
            dispatch,
        }, data) {
            commit('updateBmLeaderboardDate', data);
            dispatch('updatePageDataGeneric', 'bmDates');
        },
        updateDate ({
            rootState,
            commit,
            dispatch,
        }, date) {
            commit('updateDate', {
                pageName: rootState.ui.pageName,
                date,
            });
            dispatch('updatePageDataGeneric', 'pageDates');
        },
        async updateChosenDashboard ({
            commit,
            dispatch,
        }, data) {
            if (data.isEdit) {
                commit('editCurrentDashboardView', data.data);
                commit('updateDashboardOrder');
                const options = {
                    dashboardId: data.data.id,
                    idsChanged: true,
                };
                dispatch('updateDashboardDataInDatabase', options);
            } else {
                await dispatch('removeExistingSeriesSubscriptions', true, {
                    root: true,
                });
                await dispatch('removeExistingModuleSubscriptions', true, {
                    root: true,
                });
                commit('liveTable/clearComponentTables', null, {
                    root: true,
                });
            }
            commit('updateChosenDashboard', data.data);
            dispatch('updateActiveTabSettings', 'dashboard');
        },
        removeUserView ({
            commit,
            dispatch,
        }, data) {
            commit('removeUserView', data.index);
            commit('updateDashboardOrder');
            const toPost = {
                idDeleted: data.id,
                type: 'dashboard',
            };
            dispatch('deleteOrderedItemFromDb', toPost);
        },
        addPresetToUserDashboard ({
            commit,
            dispatch,
        }, data) {
            commit('updateChosenDashboard', data);
            commit('addUserViews', data);
            commit('updateDashboardOrder');
            const options = {
                dashboardId: data.id,
                idsChanged: true,
            };
            dispatch('updateDashboardDataInDatabase', options);
            dispatch('updateActiveTabSettings', 'dashboard');
        },
        moveDashboardOrder ({
            state,
            commit,
            dispatch,
        }) {
            commit('updateDashboardOrder');
            const options = {
                dashboardId: null,
                idsChanged: true,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        moveBingoOrder ({
            state,
            commit,
            dispatch,
        }) {
            commit('updateBingoOrder');
            const options = {
                boardId: null,
                idsChanged: true,
            };
            dispatch('updateBingoDataInDatabase', options);
        },
        batchUpdateBingo ({
            state,
            commit,
            dispatch,
        }, listOfIds) {
            commit('updateBingoOrder');
            const options = {
                boardId: null,
                idsChanged: true,
            };
            dispatch('updateBingoDataInDatabase', options);
            listOfIds.forEach((boardIdChanged) => {
                const newOptions = {
                    boardId: boardIdChanged,
                    idsChanged: false,
                };
                dispatch('updateBingoDataInDatabase', newOptions);
            });
        },
        moveModuleOnCurrent ({
            commit,
            dispatch,
        }, data) {
            commit('moveModuleOnCurrent', data);
            const options = {
                dashboardId: data.viewId,
                idsChanged: false,
            };
            dispatch('updateDashboardDataInDatabase', options);
        },
        agreeTerms ({
            commit,
            dispatch,
        }) {
            commit('agreeTerms');
            dispatch('updateBasicUserSettings');
        },
        disagreeTerms ({
            commit,
            dispatch,
        }) {
            commit('disagreeTerms');
            dispatch('updateBasicUserSettings');
        },
        setBingoMetricOverride ({
            commit,
            dispatch,
        }, payload) {
            commit('setBingoMetricOverride', payload);
            dispatch('updateCurrentBingoBoardInDatabase');
        },
        updateChartHoverChoice ({
            commit,
            dispatch,
        }, payload) {
            commit('updateChartHoverChoice', payload);
            dispatch('updateBasicUserSettings');
        },
        updateShowNearestPointOnTooltip ({
            commit,
            dispatch,
        }, payload) {
            commit('updateShowNearestPointOnTooltip', payload);
            dispatch('updateBasicUserSettings');
        },
        updateStepChartChoice ({
            commit,
            dispatch,
        }, payload) {
            commit('updateStepChartChoice', payload);
            dispatch('updateBasicUserSettings');
        },
        updateFullChartFunctionality ({
            commit,
            dispatch,
        }, payload) {
            commit('updateFullChartFunctionality', payload);
            dispatch('updateBasicUserSettings');
        },
        updateFullDAChartAxes ({
            commit,
            dispatch,
        }, payload) {
            commit('updateFullDAChartAxes', payload);
            dispatch('updateBasicUserSettings');
        },
        updateForecastHistorySeriesToShow ({
            commit, dispatch,
        }, payload) {
            commit('updateForecastHistorySeriesToShow', payload);
            dispatch('updateBasicUserSettings');
        },
        updateMelBelowPnToleranceToUse ({
            commit, dispatch,
        }, payload) {
            commit('updateMelBelowPnToleranceToUse', payload);
            dispatch('updateBasicUserSettings');
        },
        updateExcelAsCsv ({
            commit,
            dispatch,
        }, payload) {
            commit('updateExcelAsCsv', payload);
            dispatch('updateBasicUserSettings');
        },
        updateSuppressBSADWarnings ({
            commit,
            dispatch,
        }, payload) {
            commit('updateSuppressBSADWarnings', payload);
            dispatch('updateBasicUserSettings');
        },
        updateLockedAccount ({
            commit,
            dispatch,
        }, payload) {
            commit('updateLockedAccount', payload);
            dispatch('updateBasicUserSettings', true);
        },
        updateTabUpdateValue ({
            commit,
            dispatch,
        }, payload) {
            commit('updateTabUpdateValue', payload);
            dispatch('updateBasicUserSettings');
        },
        updateSideNavVisible ({
            commit,
            dispatch,
        }, payload) {
            commit('updateSideNavVisible', payload);
            dispatch('updateBasicUserSettings');
        },
        updateTopLevelValue ({
            commit,
            dispatch,
        }, payload) {
            commit('updateTopLevelValue', payload);
            dispatch('updateBasicUserSettings');
        },
        updateLongTermSetting ({
            commit,
            dispatch,
        }, data) {
            commit('updateLongTermSetting', data);
            dispatch('updateBasicUserSettings');
        },
        setUseColouredFlagsForFlaggedBoaActions ({
            commit,
            dispatch,
        }, value) {
            commit('setUseColouredFlagsForFlaggedBoaActions', value);
            dispatch('updateBasicUserSettings');
        },
    },
    mutations: {
        updateLongTermSetting (state, data) {
            Vue.set(state.longTermSettings, data.property, data.value);
        },
        updateChartHoverChoice (state, value) {
            state.chartHoverChoice = value;
        },
        updateShowNearestPointOnTooltip (state, value) {
            state.showNearestPointOnTooltip = value;
        },
        updateStepChartChoice (state, value) {
            state.stepChartOption = value;
        },
        updateFullChartFunctionality (state, value) {
            state.fullChartFunctionality = value;
        },
        updateFullDAChartAxes (state, value) {
            state.fullDAChartAxes = value;
        },
        updateForecastHistorySeriesToShow (state, value) {
            state.forecastHistorySeriesToShow = value;
        },
        updateMelBelowPnToleranceToUse (state, value) {
            state.melBelowPnToleranceToUse = value;
        },
        updateExcelAsCsv (state, value) {
            state.excelAsCsv = value;
        },
        updateSuppressBSADWarnings (state, value) {
            state.suppressBSADWarnings = value;
        },
        updateLockedAccount (state, value) {
            state.lockedAccount = value;
        },
        updateTabUpdateValue (state, value) {
            state.tabUpdateValue = value;
        },
        updateSideNavVisible (state, value) {
            state.sideNavVisible = value;
        },
        updateTopLevelValue (state, value) {
            Vue.set(state, value.option, value.value);
        },
        setBingoMetricOverride (state, config) {
            const cardIndex = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.findIndex((o) => o.rowVal === config.rowVal && o.colVal === config.colVal);
            if (cardIndex > 0) {
                Vue.set(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards[cardIndex], 'stringMetric', config.stringMetric);
            }
        },
        editCurrentDashboardView (state, data) {
            const targetedViewId = state.pages.userDashboard.user.views.findIndex(o => o.id === data.id);
            Vue.set(state.pages.userDashboard.user.views, targetedViewId, data);
        },
        moveModuleOnCurrent (state, data) {
            const viewToTarget = state.pages.userDashboard.user.views.find(o => o.id === data.viewId);
            // //console.log(viewToTarget.modules);
            const startIndex = data.from;
            const endIndex = data.to;
            const tempArray = [];
            for (let i = 0; i < viewToTarget.modules.length; i++) {
                tempArray.push(viewToTarget.modules[i]);
            }
            if (startIndex < endIndex) {
                for (let i = startIndex; i < endIndex; i++) {
                    Vue.set(viewToTarget.modules, i, tempArray[i + 1]);
                }
            }
            if (startIndex > endIndex) {
                for (let i = endIndex + 1; i < startIndex + 1; i++) {
                    Vue.set(viewToTarget.modules, i, tempArray[i - 1]);
                }
            }
            Vue.set(viewToTarget.modules, endIndex, tempArray[startIndex]);
            // const origin = viewToTarget.modules[data.from];
            // // viewToTarget.modules[data.from] = viewToTarget.modules[data.to];
            // Vue.set(viewToTarget.modules, data.from, viewToTarget.modules[data.to]);
            // Vue.set(viewToTarget.modules, data.to, origin);
        },
        updateDashboardOrder (state) {
            const newOrder = [];
            const viewsToUse = state.pages.userDashboard.user.views;
            viewsToUse.forEach((dashboard) => {
                newOrder.push(dashboard.id);
            });
            state.pages.userDashboard.user.dashboardIdsInOrder = newOrder;
        },
        updateBingoOrder (state) {
            const newOrder = [];
            Object.keys(state.pages.bingoNew.boards).forEach((board) => {
                newOrder.push(state.pages.bingoNew.boards[board].id);
            });
            state.pages.bingoNew.boardIdsInOrder = newOrder;
        },
        updateNotificationOrder (state) {
            const newOrder = [];
            state.pages.subscriptions.allSubscriptions.forEach((sub) => {
                newOrder.push(sub.uiId);
            });
            state.pages.subscriptions.subscriptionsInOrder = newOrder;
        },
        updateChosenDashboard (state, data) {
            // first set user current tab with same object
            if (data.isParent) return;
            state.pages.userDashboard.user.activeTab = {
                id: data.id,
                name: data.name,
                description: data.description,
                favourite: data.favourite,
                colour: data.colour ? data.colour : 'rgb(247, 166, 0)',
                icon: data.icon ? data.icon : 'icofont-chart-line',
                isParent: data.isParent,
                parentId: data.parentId,
                enactType: data.enactType,
            };
        },
        addUserViews (state, data) {
            state.pages.userDashboard.user.views.unshift(data);
        },
        mergeSettings (state, data) {
            const overwriteMerge = (data, state, options) => state;
            const newState = merge(state, data, {
                arrayMerge: overwriteMerge,
            });
            Object.keys(state).forEach(key => {
                Vue.set(state, key, newState[key]);
            });
            // Object.assign(state, newState);
        },
        mergeOldAndNewSettings (state) {
            state.pages.userDashboard.user.views.forEach((dashboard) => {
                for (let i = 0; i < dashboard.modules.length; i++) {
                    const dbModule = dashboard.modules[i];
                    if (dbModule.hasOwnProperty('info') && dbModule.info.type === 'BM Feed') {
                        Vue.set(dashboard.modules, i, {
                            info: {
                                id: uuid.v4(),
                                type: 'LiveTable',
                                size: 'Medium',
                            },
                            data: {
                                moduleType: 'BmFeed',
                                moduleName: 'BM Feed',
                                inputComponent: null,
                            },
                        });
                    }
                }
            });
            state.versionNumber = 0.2;
        },
        convertOldBingoToNew (state) {
            let rowsArray = [];
            let columnsArray = [];
            const cards = [];

            if (state.pages.bingo.gridLayout != null && state.pages.bingo.gridLayout.length > 0) {
                for (let rowIndex = 0; rowIndex < state.pages.bingo.gridLayout.length; rowIndex++) {
                    const rowValueToSave = rowIndex + 1;
                    rowsArray.push(rowValueToSave);
                    for (let colIndex = 0; colIndex < state.pages.bingo.gridLayout[rowIndex].length; colIndex++) {
                        const colValueToSave = colIndex + 1;
                        if (rowIndex == 0) {
                            columnsArray.push(colValueToSave);
                        }
                        const card = state.pages.bingo.gridLayout[rowIndex][colIndex];
                        if (card !== null) {
                            card.rowVal = rowValueToSave;
                            card.colVal = colValueToSave;
                            cards.push(card);
                        }
                    }
                }
            } else {
                rowsArray = [1, 2, 3, 4, 5, 6, 7];
                columnsArray = [1, 2, 3, 4, 5, 6, 7, 8];
            }
            //  state.pages.bingo.gridLayout = [];
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].rowsArray = rowsArray;
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].columnsArray = columnsArray;
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards = cards;
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].name = 'Board 1';
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].id = uuid.v4();
            state.versionNumber = 0.3;
        },
        setBoaWindowOption (state, status) {
            if (state.boaWindowType === null) {
                Vue.set(state, 'boaWindowType', { all: 'current' });
            }
            if (typeof state.boaWindowType === 'string') {
                Vue.set(state, 'boaWindowType', { all: state.boaWindowType });
            }
            if (status.value === 'none') Vue.delete(state.boaWindowType, status.page);
            else Vue.set(state.boaWindowType, status.page, status.value);
        },
        setAutorollOption (state, status) {
            state.autoRollover = status;
        },
        setMapPagePreferences (state, mapPagePreferences) {
            state.pages.map = mapPagePreferences;
        },
        setPlantIndexPagePreferences (state, plantIndexPagePreferences) {
            state.pages.plantIndex = plantIndexPagePreferences;
        },
        setBoaDashboardMapPagePreferences (state, mapPagePreferences) {
            state.pages.boaDashboardMap = mapPagePreferences;
        },
        setMapLivePagePreferences (state, mapLivePagePreferences) {
            state.pages.mapLive = mapLivePagePreferences;
        },
        setBmLeaderboardPagePreferences (state, bmLeaderboardPagePreferences) {
            state.pages.bmLeaderboard = bmLeaderboardPagePreferences;
        },
        setRemitPagePreferences (state, remitPagePreferences) {
            state.pages.remit = remitPagePreferences;
        },
        setBoaPreferences (state, preference) {
            Vue.set(state.pages.boa, preference.key, preference.value);
        },
        setReverseNivSign (state, status) {
            state.reverseNivSign = status;
        },
        setReverseNivForecastSign (state, status) {
            state.reverseNivForecastSign = status;
        },
        setShowZoneInPlantNameBingo (state, value) {
            state.showZoneInPlantNameBingo = value;
        },
        setnivInMwh (state, status) {
            state.nivInMwh = status;
        },
        setleaderboardCapacityInMwh (state, status) {
            state.leaderboardCapacityInMwh = status;
        },
        setShowTimer (state, status) {
            state.showTimer = status;
        },
        setfilterAcceptedUnwinds (state, status) {
            state.filterAcceptedUnwinds = status;
        },
        setfilterUnacceptedUnwinds (state, status) {
            state.filterUnacceptedUnwinds = status;
        },
        setfilterTrackedPlants (state, status) {
            state.filterTrackedPlants = status;
        },
        setLinkBoaComparerFilters (state, status) {
            state.pages.boaComparer.linkBoaComparerFilters = status;
        },
        setOrderAcceptedFirst (state, status) {
            state.orderAcceptedFirst = status;
        },
        setChosenPlants (state, chosenPlants) {
            state.chosenPlantGroupName = chosenPlants.groupName;
            Vue.set(state.chosenPlantsByGroup, chosenPlants.groupName, chosenPlants.valuesToAdd.map(el => el.id));
            state.chosenPlants = chosenPlants.valuesToAdd;

            if (Object.keys(state.plantGroupColourOverrideByGroup).includes(chosenPlants.groupName)) {
                state.plantGroupColourOverride = state.plantGroupColourOverrideByGroup[chosenPlants.groupName].override;
                state.chosenPlantGroupColour = state.plantGroupColourOverrideByGroup[chosenPlants.groupName].colour;
                if (!state.chosenPlantGroupColour) {
                    const newObj = {
                        override: state.plantGroupColourOverride,
                        colour: state.defaultPlantGroupColour,
                    };
                    Vue.set(state.plantGroupColourOverrideByGroup, chosenPlants.groupName, newObj);
                }
            } else {
                const newObj = {
                    override: false,
                    colour: state.defaultPlantGroupColour,
                };
                Vue.set(state.plantGroupColourOverrideByGroup, chosenPlants.groupName, newObj);
                state.plantGroupColourOverride = false;
                state.chosenPlantGroupColour = state.defaultPlantGroupColour;
            }
        },
        setChosenPlantColour (state, chosenColour) {
            state.chosenPlantGroupColour = chosenColour.colour;
            const entry = {
                colour: state.chosenPlantGroupColour,
                override: state.plantGroupColourOverride,
            };
            Vue.set(state.plantGroupColourOverrideByGroup, chosenColour.groupName, entry);
        },
        setPlantColourOverride (state, overrideColourOption) {
            state.plantGroupColourOverride = overrideColourOption.plantGroupColourOverride;
            const entry = {
                colour: state.chosenPlantGroupColour,
                override: state.plantGroupColourOverride,
            };
            Vue.set(state.plantGroupColourOverrideByGroup, overrideColourOption.groupName, entry);
        },
        removeChosenPlants (state, groupName) {
            Vue.delete(state.chosenPlantsByGroup, groupName);

            Vue.delete(state.plantGroupColourOverrideByGroup, groupName);
            if (Object.keys(state.chosenPlantsByGroup).length == 0) {
                state.chosenPlantGroupName = null;
                state.chosenPlants = [];
            }
        },
        renameChosenPlants (state, chosenPlants) {
            Vue.set(state.chosenPlantsByGroup, chosenPlants.newValue, state.chosenPlantsByGroup[chosenPlants.oldValue]);
            Vue.delete(state.chosenPlantsByGroup, chosenPlants.oldValue);

            Vue.set(state.plantGroupColourOverrideByGroup, chosenPlants.newValue, state.plantGroupColourOverrideByGroup[chosenPlants.oldValue]);
            Vue.delete(state.plantGroupColourOverrideByGroup, chosenPlants.oldValue);
        },
        setNewHistoricAnalysisQuery (state, value) {
            state.pages.historicQueries.push(value);
        },
        deleteHistoricAnalysisQuery (state, uniqueId) {
            const index = state.pages.historicQueries.findIndex((x) => x.uniqueId === uniqueId);
            if (index > -1) {
                Vue.delete(state.pages.historicQueries, index);
            }
        },
        setGlobalBingoChoices (state, value) {
            Vue.set(state.globalBingoChoices, value.bingoEnum, value.metricsToShow);
            if (value.sound.sound) {
                if (value.sound.sound === 'None') Vue.delete(state.globalBingoSoundChoices, value.bingoEnum);
                else Vue.set(state.globalBingoSoundChoices, value.bingoEnum, value.sound);
            }
        },
        elexonMinuteDelay (state, value) {
            state.minutesDelayToReport = value;
        },
        setGenericTableFilters (state, filters) {
            Vue.set(state.genericTableFilters, filters.name, filters.filters);
        },
        deleteGenericTableFilters (state, filters) {
            Vue.delete(state.genericTableFilters, filters.name);
        },
        updateBmLeaderboardDate (state, date) {
            state.pages.bmDates.dateObject = date;
        },
        updateDate (state, data) {
            const pageName = data.pageName;
            const pageDates = state.pages.pageDates;
            if (!pageDates.hasOwnProperty(pageName)) Vue.set(pageDates, pageName, {});

            Vue.set(pageDates[pageName], 'dateObject', data.date);
        },
        setPlantSelections (state, value) {
            state.plantSelection = value;
        },
        setShowGeneration (state, value) {
            state.showGeneration = value;
        },
        setReversePlantActivityOrder (state, value) {
            state.reversePlantActivityOrder = value;
        },
        setPlantActivityFirstColumnOption (state, value) {
            state.pages.plantActivity.firstColumnOption = value;
        },
        setPlantActivityDisplayOption (state, value) {
            state.plantActivityDisplayOption = value;
        },
        removeUserView (state, index) {
            state.pages.userDashboard.user.views.splice(index, 1);
        },
        removeUserModule (state, data) {
            const viewToTarget = state.pages.userDashboard.user.views.find(o => o.id === data.viewId);
            // let currentView = state.pages.userDashboard.user.activeTab;
            const moduleKey = viewToTarget.modules.findIndex(o => {
                if (o.hasOwnProperty('id') && o.id === data.moduleId) {
                    return true;
                } else if (o.info && o.info.hasOwnProperty('id') && o.info.id === data.moduleId) {
                    return true;
                }
                return false;
            });
            if (moduleKey !== -1) viewToTarget.modules.splice(moduleKey, 1);
        },
        updateUserViews (state, data) {
            state.pages.userDashboard.user.views[data.key].modules = data.modules;
            state.pages.userDashboard.user.views[data.key].name = data.name;
        },
        addUserModule (state, chart) {
            state.pages.userDashboard.user.views[chart.key].modules.push(
                chart.data,
            );
        },
        updateUserModule (state, chart) {
            const index = state.pages.userDashboard.user.views[
                chart.key
            ].modules.findIndex((module) => module.id === chart.data.id);
            state.pages.userDashboard.user.views[chart.key].modules[index] = chart.data;
        },
        updateActiveView (state, data) {
            state.pages.userDashboard.user.activeTab = data;
        },
        setUserSettings (state, settings) {
            state.pages = settings.pages;
        },
        setUserSubscriptions (state, settings) {
            if (settings.pages.subscriptions !== undefined) {
                state.pages.subscriptions.allSubscriptions = settings.pages.subscriptions.allSubscriptions;
            } else {
                Vue.set(state.pages, 'subscriptions', {});
                Vue.set(state.pages.subscriptions, 'allSubscriptions', []);
            }
        },
        addSubscription (state, data) {
            state.pages.subscriptions.allSubscriptions.push(data);
        },
        updateSubscription (state, data) {
            Vue.set(
                state.pages.subscriptions.allSubscriptions,
                data.key,
                data.sub,
            );
        },
        deleteSubscription (state, data) {
            Vue.delete(state.pages.subscriptions.allSubscriptions, data.key);
        },
        setUserId (state, settings) {
            state.userId = settings;
        },
        setUserFromToken (state, token) {
            state.userId = token.oid;
            state.userEmail = token.emails[0];
        },
        updateBodColour (state, data) {
            if (data.isBid) state.bidColour = data.colour;
            else state.offerColour = data.colour;
        },
        updateBingoColour (state, data) {
            if (data.colour === null) Vue.delete(state.bingoColourOverrides, data.bingoEnum);
            else Vue.set(state.bingoColourOverrides, data.bingoEnum, data.colour);
        },
        insertBingoRow (state, data) {
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].rowsArray.splice(data.index, 0, data.data);
        },
        insertBingoColumn (state, data) {
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].columnsArray.splice(data.index, 0, data.data);
        },
        insertBingoSepColumn (state, data) {
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorColumns.push(data.data);
        },
        insertBingoSepRow (state, data) {
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorRows.push(data.data);
        },
        deleteBingoColumn (state, index) {
            const colIndexToDelete = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].columnsArray[index];
            Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].columnsArray, index);
            const numberToLoopOver = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.length;
            for (let i = 0; i < numberToLoopOver; i++) {
                if (i >= state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.length) break;
                const card = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards[i];
                if (card.colVal === colIndexToDelete) {
                    Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards, i);
                    i -= 1;
                }
            }

            const separatorIndexToDelete = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorColumns.findIndex((o) => o === colIndexToDelete);
            if (separatorIndexToDelete > -1) Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorColumns, separatorIndexToDelete);
        },
        deleteBingoRow (state, index) {
            const rowIndexToDelete = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].rowsArray[index];
            Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].rowsArray, index);
            const numberToLoopOver = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.length;
            for (let i = 0; i < numberToLoopOver; i++) {
                if (i >= state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.length) break;
                const card = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards[i];
                if (card.rowVal === rowIndexToDelete) {
                    Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards, i);
                    i -= 1;
                }
            }
            const separatorIndexToDelete = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorRows.findIndex((o) => o === rowIndexToDelete);
            if (separatorIndexToDelete > -1) Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].separatorRows, separatorIndexToDelete);
        },
        addBingoCardData (state, data) {
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.push(data);
        },
        setBingoChosenSize (state, data) {
            Vue.set(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard], 'chosenSize', data);
        },
        setBingoCardData (state, data) {
            Vue.set(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards, data.index, data.card);
        },
        addBulkCards (state, data) {
            data.forEach((card) => {
                state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.push(card);
            });
        },
        updateBingoBoard (state, boardId) {
            Vue.set(state.pages.bingoNew, 'activeBoard', boardId);
        },
        addBingoBoard (state, data) {
            // data: { name: '', board: {}}
            Vue.set(state.pages.bingoNew.boards, data.board.id, data.board);
            state.pages.bingoNew.activeBoard = data.board.id;
        },
        renameBingoBoard (state, data) {
            // data: { oldName: '', newName: '' }
            state.pages.bingoNew.boards[data.id].name = data.newName;
        },
        removeBingoBoard (state, boardId) {
            Vue.delete(state.pages.bingoNew.boards, boardId);
            if (state.pages.bingoNew.activeBoard === boardId) {
                state.pages.bingoNew.activeBoard = Object.keys(state.pages.bingoNew.boards)[0];
            }
        },
        deleteBingoCardData (state, data) {
            const targetCardIndex = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.findIndex(o => o.rowVal === data.rowVal && o.colVal === data.colVal);
            if (targetCardIndex > -1) Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards, targetCardIndex);
        },
        replaceBingoCard (state, data) {
            const targetCardIndex = state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.findIndex(o => o.rowVal === data.rowVal && o.colVal === data.colVal);
            if (targetCardIndex > -1) Vue.delete(state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards, targetCardIndex);
            state.pages.bingoNew.boards[state.pages.bingoNew.activeBoard].cards.push(data);
        },
        toggleColourMode (state, colour) {
            state.colour = colour;
        },
        sortBoaAcceptedTable (state, sort) {
            Vue.set(state, 'boaAcceptedTableSortOption', { order: sort.order, metric: sort.metric });
        },
        setDefaultCountry (state, country) {
            state.country = country;
        },
        updateBingoSettings (state, settings) {
            state.pages.bingo.gridLayout = settings.gridLayout;
            state.pages.bingo.config = settings.config;
        },
        agreeTerms (state) {
            state.terms.agreed = true;
            state.terms.date = Vue.moment().format('MMMM Do YYYY, h:mm:ss a');
        },
        disagreeTerms (state) {
            state.terms.agreed = false;
            state.terms.date = Vue.moment().format('MMMM Do YYYY, h:mm:ss a');
        },
        updateModuleDate (state, data) {
            state.pages.userDashboard.user.views[data.viewKey].modules[
                data.moduleKey
            ].date = data.date;
        },
        updateExtraChartOption (state, data) {
            const chart = state.pages.userDashboard.user.views[data.viewKey].modules[data.moduleKey];
            if (!chart.hasOwnProperty('extraChartInfo')) chart.extraChartInfo = {};

            Vue.set(chart.extraChartInfo, data.property, data.value);
        },
        updateModuleSizeAndPosition (state, data) {
            const toUpdate = state.pages.userDashboard.user.views[data.dashboardIndex].modules[
                data.module.index
            ];
            if (toUpdate.hasOwnProperty('info')) Vue.set(toUpdate.info, 'sizeAndPosition', data.module.sizeAndPosition);
            else Vue.set(toUpdate, 'sizeAndPosition', data.module.sizeAndPosition);
        },
        updateBoaStackSort (state, data) {
            state.pages.userDashboard.user.views[data.activeTabIndex].modules[
                data.moduleIndex
            ].data.sorting = data.sorting;
        },
        updateForecastHistory (state, data) {
            state.pages.userDashboard.user.views[data.activeTabIndex].modules[
                data.moduleIndex
            ].data.daysAfter = data.daysAfter;

            state.pages.userDashboard.user.views[data.activeTabIndex].modules[
                data.moduleIndex
            ].data.showDeltaChart = data.showDeltaChart;

            state.pages.userDashboard.user.views[data.activeTabIndex].modules[
                data.moduleIndex
            ].data.dateFormat = data.dateFormat;
        },
        updateModuleNote (state, data) {
            state.pages.userDashboard.user.views[data.viewKey].modules[
                data.moduleKey
            ].data.text = data.text;
            state.pages.userDashboard.user.views[data.viewKey].modules[
                data.moduleKey
            ].data.isEdit = false;
        },
        setUseColouredFlagsForFlaggedBoaActions (state, status) {
            state.useColouredFlagsForFlaggedBoaActions = status;
        },
    },
};
